<template>
    <b-form class="mt-2" @submit.prevent="onSubmit">
        <form-input-radio-group
            v-model="codeKnown"
            :options="codeKnownOptions"
            stacked
        />

        <div v-if="codeKnown">
            <form-input
                :label="$t('typeSelection.form.codeLabel')"
                v-model="code"
                :validation="$v.code"
            />

            <div class="alert alert-danger" v-show="codeInvalid">
                {{$t('typeSelection.form.codeInvalid')}}
            </div>
        </div>

        <div v-else>
            <form-input
                :label="$t('typeSelection.form.nameLabel')"
                v-model="name"
                :validation="$v.name"
            />
            <form-input
                :label="$t('typeSelection.form.phoneLabel')"
                v-model="phoneNumberComputed"
                :validation="$v.phone"
                :formatter="phoneFormatter"
                :hint="$t('basicData.desc.phoneFormatHint')"
            />
            <form-input-textarea
                :label="$t('typeSelection.form.addressLabel')"
                v-model="address"
                :validation="$v.address"
            />
        </div>

        <div class="form-actions mt-4">
            <b-btn
                variant="primary"
                type="submit"
                class="mr-2"
                :disabled="$v.$invalid || loading"
            >
                {{(loading) ? $t('typeSelection.form.verify') : $t('typeSelection.form.submit')}}
            </b-btn>

            <b-btn
                variant="secondary"
                @click.prevent="onCancel"
            >
                {{$t('typeSelection.form.cancel')}}
            </b-btn>
        </div>
    </b-form>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { parsePhoneNumberFromString, parseIncompletePhoneNumber } from 'libphonenumber-js'

export default {
    name: 'IzCodeForm',
    data () {
        return {
            codeKnown: true,
            code: null,
            codeVerified: false,
            loading: false,
            name: null,
            address: null,
            phone: null,
            codeInvalid: false
        }
    },
    computed: {
        codeKnownOptions () {
            return [
                {
                    value: true,
                    text: this.$t('typeSelection.form.knownCode')
                },
                {
                    value: false,
                    text: this.$t('typeSelection.form.unknownCode')
                }
            ]
        },
        phoneNumberComputed: {
            get () {
                return this.phone
            },
            set (value) {
                const number = this.validatePhoneNumber(value)
                this.phone = (number !== null) ? number : value
            }
        }
    },
    methods: {
        phoneFormatter (number) {
            if (number) {
                return parseIncompletePhoneNumber(number)
            }
            return number
        },
        validatePhoneNumber (value) {
            const phoneNumber = parsePhoneNumberFromString(`${value}`)
            if (phoneNumber && phoneNumber.nationalNumber.length >= 9) {
                return phoneNumber.format('E.164')
            }
            return null
        },
        onCancel () {
            this.$emit('cancel')
        },
        onSubmit () {
            this.$v.$reset()
            this.codeInvalid = false
            this.$nextTick(() => {
                if (this.$v.$invalid) {
                    this.$notify.error(this.$t('errors.someDataIsMissing'))
                    this.$v.$touch()
                } else {
                    if (this.codeKnown) {
                        this.loading = true
                        this.$api.externalAcquirers.checkIzCode(this.code)
                            .then((response) => {
                                this.$emit('loggedin', {
                                    izCode: `${this.code}`,
                                    name: response.data.name,
                                    address: null,
                                    phone: null
                                })
                            }).catch(() => {
                                this.codeInvalid = true
                            }).finally(() => {
                                this.$nextTick(() => {
                                    this.loading = false
                                })
                            })
                    } else {
                        this.$emit('loggedin', {
                            izCode: null,
                            name: `${this.name}`,
                            address: `${this.address}`,
                            phone: `${this.phone}`
                        })
                    }
                }
            })
        }
    },
    validations: {
        code: {
            required: requiredIf(function () {
                return this.codeKnown
            })
        },
        name: {
            required: requiredIf(function () {
                return !this.codeKnown
            })
        },
        address: {
            required: requiredIf(function () {
                return !this.codeKnown
            })
        },
        phone: {
            required: requiredIf(function () {
                return !this.codeKnown
            }),
            numberValidation (value) {
                if (this.codeKnown) {
                    return true
                }
                if (!value) {
                    return false
                }
                const number = parsePhoneNumberFromString(`${value}`)
                if (number && number.isValid()) {
                    return true
                }
                return false
            }
        }
    }
}
</script>
