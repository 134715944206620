<template>
    <b-modal id="acquirerLoginForm" hide-footer>
        <template #modal-title>
            {{$t('typeSelection.izInfo')}}
        </template>

        <loader-overlay
            :loading="saving"
        >
            <iz-code-form
                @cancel="onCancelAcquirer"
                @loggedin="onSubmitAcquirer"
            />
        </loader-overlay>
    </b-modal>
</template>

<script>
import IzCodeForm from './IzCodeForm.vue'
import LoaderOverlay from './LoaderOverlay.vue'
export default {
    name: 'AcquirerLoginFormModal',
    components: {
        IzCodeForm,
        LoaderOverlay
    },
    props: {
        sessionData: {
            required: false,
            default: () => null
        }
    },
    data () {
        return {
            saving: false
        }
    },
    methods: {
        async onSubmitAcquirer (externalAcquirer) {
            if (externalAcquirer) {
                if (!this.sessionData) {
                    await this.$api.clientSession.create({})
                }
                this.saving = true
                try {
                    const response = await this.$api.clientSession.update({ externalAcquirer })
                    this.$emit('update', response.data)
                } catch (error) {
                    console.error(error)
                    this.$notify.error(this.$t('errors.sessionUpdateFailed'))
                } finally {
                    this.$nextTick(() => {
                        this.saving = false
                        this.onCancelAcquirer()
                    })
                }
            }
        },
        onCancelAcquirer () {
            this.$bvModal.hide('acquirerLoginForm')
        }
    }
}
</script>
